<template>
	<v-dialog v-model="dialogNewCommand" @click:outside="$v.$reset()" max-width="550px">
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" color="pink" fab small class="mx-3">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</template>

		<v-card>
			<v-form class="pa-2" @submit.prevent="SaveCommand">
				<v-card-title>
					<span class="text-center">New Command</span>
				</v-card-title>

				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-text-field 
									clearable
									v-model.trim="$v.name.$model"
									:error-messages="NameErrors"
									@input="$v.name.$touch()"
									@blur="$v.name.$touch()"
									label="Command*" 
									required
								></v-text-field>
							</v-col>

							<v-col cols="12">
								<v-textarea 
									clearable
									v-model.trim="$v.message.$model"
									:error-messages="MsgErrors"
									@input="$v.message.$touch()"
									@blur="$v.message.$touch()"
									label="Message*" 
									required
								></v-textarea>
							</v-col>
						</v-row>
					</v-container>
					<small>* indicates required field</small>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeCommandDialog">Close</v-btn>
					<v-btn color="blue darken-1" text :loading="loadingBtn" :disabled="loadingBtn" type="submit">Save</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

const commandStart = (value) => value.startsWith('!');

export default {
	data() {
		return {
			dialogNewCommand: false,
			loadingBtn: false,
			name: '',
			message: '',
			command: null
		}
	},

	validations() {
		return {
			name: {
				required,
				commandStart
			},
			message: {
				required
			},
		};
	},

	computed: {
		NameErrors() {
			const errors = [];
			if (!this.$v.name.$dirty) return errors;
			!this.$v.name.required && errors.push('Command is required.');
			!this.$v.name.commandStart && errors.push('Command must start with !');
			return errors;
		},

		MsgErrors() {
			const errors = [];
			if (!this.$v.message.$dirty) return errors;
			!this.$v.message.required && errors.push('Message is required.');
			return errors;
		},
	},

	methods: {
		closeCommandDialog() {
			this.dialogNewCommand = false;
			this.$v.$reset();
		},

		async SaveCommand() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.loadingBtn = true;

				try {
					const request = await fetch(this.$store.state.base_url + '/commands/new', {
						method: 'POST',
						body: JSON.stringify({
							name: this.name,
							message: this.message,
						}),
						headers: {
							"Content-type" : "application/json; charset=UTF-8",
							"Authorization" : this.$cookies.get('mgn_sessid'),
						}
					});
					const res = await request.json();
					
					if (request.ok) {	
						if (res.isCommandAdded) {
							this.$v.$reset();
							
							this.name = '';
							this.message = '';

							this.$emit('NewCommandAdded');
							this.dialogNewCommand = false;

							this.$notify({
								group: 'main',
								type: 'success',
								title: this.$store.state.app_name,
								text: 'Command added successfully!'
							});
						}
						else {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: res.error
							});
						}
					}
					else if (request.status === 401) {
						this.sessionExpiredHandler(res);
					}
					else {
						if (res.error) {
							if (this.$store.state.error_types.includes(res.error)) {
								this.sessionExpiredHandler(res);
							}
							else {
								throw res.error;
							}
						}
					}
				}
				catch (error) {
					this.$notify({
						group: 'main',
						type: 'error',
						title: this.$store.state.app_name,
						text: error
					});
				}
				finally {
					this.loadingBtn = false;
				}
			}
		},
	},
}
</script>